<template>
    <div class="cookie-policy small-intro" v-if="pageData">
        <div class="intro-section container">
            <div v-if="this.pageData.attributes.title">
                <h1 class="light" > {{this.pageData.attributes.title}}</h1>
                <p v-html="this.pageData.attributes.body.value">
                </p>
            </div>
        </div>
    </div>
    <div class="loading" v-else></div>
</template>
<script>

export default {
    name:"cookie-policy",
    components: {
    },
    data:()=>{
        return {
            currentPageId:"b843cf8d-4cad-41fc-9304-8eaae9038f79",
            pageData:[],
        }
    },
    methods:{
    },
    mounted(){
        fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/page/${this.currentPageId}`, {
        method: "GET",
        mode: "cors",
        })
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            this.pageData = json.data;
        })
        .catch(function (error) {
            console.log("Request failed", error);
        });
    }
}


</script>
<style lang="scss" scoped>


</style>